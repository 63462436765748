<script>
import SimpleLoading from "./SimpleLoading";
export default {
	components: {
		SimpleLoading,
	},
	props: {
		text: {
			required: true,
			type: String,
		},
		angle: {
			required: false,
			type: Boolean,
			default: false,
		},
		buttonColor: {
			required: false,
			type: String,
			default() {
				return "bg-orange";
			},
		},
		loadingColor: {
			required: false,
			type: String,
		},
		loadingFontSize: {
			required: false,
			type: String,
		},
		isLoading: {
			required: false,
			type: Boolean,
		},
	},
};
</script>

<template>
	<div
		class="inline-flex items-center justify-center secondary-button w-auto text-xl text-center text-white b rounded-full px-4 py-2 cursor-pointer"
		:class="buttonColor"
	>
		<span v-if="!isLoading">{{ text }}</span>
		<simple-loading v-else :fontSize="loadingFontSize" :color="loadingColor"></simple-loading>
		<span
			v-if="angle"
			class="ml-3 angle block w-6 h-6 rounded-full flex justify-center items-center"
		>
			<font-awesome-icon icon="fa-solid fa-angle-right"
		/></span>
	</div>
</template>

<style lang="scss" scoped>
.secondary-button {
	min-width: 220px;
	box-shadow: 0 16px 12px rgba($color: #224c57, $alpha: 0.25);
	border: get-vh(12px) solid #f2f6ff;
	// @media (min-width: 768px) {
	// 	&:hover {
	// 		animation: bounce-in 0.5s;
	// 	}
	// }
}

@keyframes bounce-in {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.15);
	}
	100% {
		transform: scale(1);
	}
}
.angle {
	border: 2px solid $blue;
}
</style>
