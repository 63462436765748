<script>
export default {
	methods: {
		getImage(image) {
			return `${require(`@/assets/images/components/MAINLOGO/${image}`)}`;
		},
	},
};
</script>

<template>
	<div class="relative my-2 flex items-center justify-center">
		<img class="h-24 object-contain" :src="getImage('star_heart.png')" />
	</div>
</template>

<style lang="scss" scoped></style>
